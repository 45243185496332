<template>
  <div>
    <DashboardHeader
      header-tag="h3"
      margin="small"
      :title="$t('general.rates')"
    >
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        @click="$emit('add-rate')"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>
    <ChecTable inner-class="rates-section__table" snug>
      <thead>
        <tr>
          <th>{{ $t('general.rate') }}</th>
          <th>{{ $t('general.amount') }}</th>
          <th>{{ $t('general.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="3" class="rates-section__loading-cell">
            <ChecLoading />
          </td>
        </tr>
        <template v-else>
          <RateRow
            v-for="rate in updatedRates"
            :key="rate.id"
            :rate="rate"
            :deleted="deletedRateIds.includes(rate.id)"
            :show-validation="showValidation"
            @change="updateExistingRate"
            @delete="() => $emit('delete-rate', rate.id)"
            @undo-delete="() => $emit('undo-delete-rate', rate.id)"
          />
          <RateRow
            v-for="(rate, index) in newRates"
            :key="index"
            :rate="rate"
            :show-validation="showValidation"
            @change="(newRate) => updateNewRate(index, newRate)"
            @delete="$emit('remove-new-rate', index)"
          />
        </template>
      </tbody>
    </ChecTable>
  </div>
</template>

<script>
import {
  ChecButton,
  ChecLoading,
  ChecTable,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import RateRow from './RateRow.vue';

export default {
  name: 'RatesSection',
  components: {
    ChecButton,
    ChecLoading,
    ChecTable,
    DashboardHeader,
    RateRow,
  },
  props: {
    deletedRateIds: Array,
    editedRates: Object,
    loading: Boolean,
    newRates: Array,
    rates: Array,
    /**
     * Whether validation error should appear on rate rows if they are missing detail
     */
    showValidation: Boolean,
  },
  computed: {
    updatedRates() {
      // Replace rates from the API with updated versions of those, and concat on new rates.
      return this.rates.map((rate) => {
        if (this.editedRates[rate.id]) {
          return this.editedRates[rate.id];
        }

        return rate;
      });
    },
  },
  methods: {
    updateExistingRate(rate) {
      this.$emit('set-edited-rates', {
        ...this.editedRates,
        [rate.id]: rate,
      });
    },
    updateNewRate(index, rate) {
      const rates = [...this.newRates];
      rates.splice(index, 1, rate);
      this.$emit('set-new-rates', rates);
    },
  },
};
</script>

<style lang="scss">
.rates-section {
  &__table {
    @apply w-full;

    tr > *:nth-child(n+3) {
      @apply text-right;
    }

    td:not(:last-child) {
      @apply align-top;
    }
  }

  &__lead-in {
    @apply mb-4;
  }

  &__product-link {
    @apply font-bold;
  }

  &__loading-cell {
    // Override specificity in UI lib...
    .chec-table--snug & {
      @apply relative p-8;
    }

    .loading {
      // See https://github.com/chec/ui-library/issues/474
      @apply z-0;
    }
  }
}
</style>
