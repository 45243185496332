<template>
  <div class="countries-regions-table">
    <DashboardHeader
      header-tag="h3"
      margin="small"
      :title="$tc('shipping.countriesRegions')"
    >
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        @click="mode = 'add-country'"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>
    <EmptyMessage
      v-if="showEmptyMessage"
      :title="$t('shipping.noCountriesRegionsTitle')"
    >
      <p>
        {{ $t('shipping.noCountriesRegions') }}
      </p>
    </EmptyMessage>
    <template v-else>
      <DashboardTable
        class="countries-regions-table__table"
        :align-right-after="2"
        :columns="[
          { title: $t('address.country'), class: 'countries-regions-table__row-header-country' },
          { title: $t('shipping.numRegions') },
          { title: $t('general.actions') },
        ]"
      >
        <TableRow
          v-for="country in countries"
          :key="country"
          class="countries-regions-table__row"
          :class="{ 'countries-regions-table__row--deleted': isDeleted(country) }"
          @click="handleEditRegions(country, zone.subdivisions[country])"
        >
          <td>
            <ChecDataPill
              v-if="isNew(country)"
              class="countries-regions-table__new"
              color="gray"
            >
              {{ $t('general.new') }}
            </ChecDataPill>
            {{ fullCountryNames[country] || country }}
          </td>
          <td>{{ regionCount(country) }}</td>
          <td class="countries-regions-table__options">
            <ChecButton
              v-if="isDeleted(country)"
              variant="round"
              color="primary"
              @click.stop="$emit('undo-delete-country', country)"
            >
              {{ $t('general.cancel') }}
            </ChecButton>
            <ChecOptionsMenu
              v-else
              menu-placement="bottom-end"
              class="dashboard-table__actions-button"
            >
              <ChecOption
                @option-selected="() =>
                  handleEditRegions(country, zone.subdivisions[country])"
              >
                {{ $t('general.edit') }}
              </ChecOption>
              <ChecOption
                destructive
                @option-selected="$emit('delete-country', country)"
              >
                {{ $t('general.delete') }}
              </ChecOption>
            </ChecOptionsMenu>
          </td>
        </TableRow>
      </DashboardTable>
    </template>
    <CountriesPanel
      v-if="mode === 'add-country'"
      :current-countries="countries"
      @change="value => $emit('add-countries', value)"
      @close="closePanel"
    />
    <RegionsPanel
      v-if="mode === 'edit-region'"
      :edited-country="editedCountry"
      :edited-regions="editedRegions"
      :current-countries="countries"
      @change="value => $emit('edit-regions', value)"
      @close="closePanel"
    />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import { mapState } from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import EmptyMessage from '@/modules/products/components/cards/VariantsCard/EmptyMessage.vue';
import CountriesPanel from './CountriesPanel.vue';
import RegionsPanel from './RegionsPanel.vue';

export default {
  name: 'CountriesRegionsTable',
  components: {
    TableRow,
    DashboardTable,
    ChecButton,
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
    DashboardHeader,
    EmptyMessage,
    CountriesPanel,
    RegionsPanel,
  },
  props: {
    /**
     * Shipping zone
     */
    zone: Object,
    newCountries: Array,
    deletedCountries: Array,
  },
  data() {
    return {
      mode: '',
      editedCountry: '',
      editedRegions: [],
    };
  },
  computed: {
    ...mapState({ fullCountryNames: 'countries' }), // Change countries to obj with named keys
    countries() {
      return [...this.zone.countries].sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    },
    showEmptyMessage() {
      return this.countries.length === 0;
    },
  },
  methods: {
    closePanel() {
      this.mode = '';
    },
    handleEditRegions(countryCode, regions) {
      if (this.isDeleted(countryCode)) {
        return;
      }
      this.mode = 'edit-region';
      this.editedCountry = countryCode;
      this.editedRegions = regions;
      this.$emit('change', countryCode, regions);
    },
    isDeleted(country) {
      return this.deletedCountries.includes(country);
    },
    isNew(country) {
      return this.newCountries.includes(country);
    },
    regionCount(country) {
      if (this.zone.subdivisions[country] === undefined
        || this.zone.subdivisions[country][0] === '*') {
        return '–';
      }
      return Array.isArray(this.zone.subdivisions[country])
        ? this.zone.subdivisions[country].length
        // Assume one region exists for countries that don't have a subdivision specification
        : '1';
    },
  },
};
</script>

<style lang="scss">
.countries-regions-table {
  &__row-header-country {
    @apply w-3/5;
  }

  &__empty-text {
    @apply text-gray-400;
  }

  &__row {
    &--deleted {
      > :not(:last-child) {
        @apply text-gray-400;
      }
    }
  }

  &__options .button {
    @apply float-right;
  }

  &__new {
    @apply mr-2;
  }
}
</style>
