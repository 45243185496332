<template>
  <ChecDropdown
    ref="dropdown"
    :options="initialised ? dropdownOptions : []"
    :loading="showLoadingInDropdown"
    :show-search="showSearch"
    :search-value="searchTerm"
    :variant="passthroughVariant"
    :value="passthroughValue"
    v-bind="dropdownProps"
    @input="emitInput"
    @search="handleSearch"
    @scroll-to-bottom="handleLoadMore"
  />
</template>

<script>
import { ChecDropdown } from '@chec/ui-library';
import debounce from 'lodash.debounce';
import { Cancel } from 'axios';
import { actions, getters, mutations } from '@/lib/pagination';
import withPropDefinedState from '@/mixins/withPropDefinedState';

// Pull prop definitions from ChecDropdown to pass through. We also extract a bunch of props that
// are fully controlled by this component and are not passed through.
const {
  loading,
  options: _1, // Renamed so that we don't pollute child scope
  showSearch,
  value: _2,
  ...dropdownProps
} = ChecDropdown.props;

export default {
  name: 'ResourceDropdown',
  components: { ChecDropdown },
  mixins: [
    withPropDefinedState({
      actions: {
        setPage: actions.SET_PAGE,
        search: actions.SEARCH_ITEMS,
        loadItem: actions.LOAD_ITEM,
      },
      functionalGetters: { isFullyLoaded: getters.IS_FULLY_LOADED },
      mutations: { setSearch: mutations.SEARCH },
      state: ['items', 'totalCount', 'additionalItems', 'isSearching', 'pageSize'],
    }),
  ],
  props: {
    /**
     * Alternate context (for the CRUD library) to pull items from state
     */
    context: {
      type: Object,
      default: () => ({}),
    },
    /**
     * A string that indicates the key in the object that should be used for the options label
     */
    labelKey: {
      type: String,
      default: null,
    },
    /**
     * The minimum number of records that should be available before a search bar appears
     */
    minSearchAmount: {
      type: Number,
      default: 15,
    },
    /**
     * A string that indicates the key in the object that should be used for the options value
     */
    valueKey: {
      type: String,
      default: 'id',
    },
    /**
     * The current value of selected option for the dropdown. Array for multi-select. String for
     * single select
     */
    value: [String, Array],
    /**
     * A list of entity IDs to exclude from the options
     */
    exclude: {
      type: Array,
      default: () => [],
    },
    // Pass through other dropdown props
    ...dropdownProps,
  },
  data() {
    return {
      page: 1,
      searchTerm: '',
      allItems: this.items || [],
      loading: false,
      initialised: false,
      totalCountWithNoSearch: null,
      selectedItems: [],
    };
  },
  computed: {
    /**
     * Computes the dropdown options
     *
     * @returns {Array}
     */
    dropdownOptions() {
      if (this.allItems.length === 0) {
        // Show an indication that we have no results when a search is complete and there are none
        if (this.searchTerm.length > 0 && !this.showLoadingInDropdown) {
          return [{
            value: '',
            label: this.$t('general.noResults'),
            disabled: true,
          }];
        }

        return [];
      }

      const options = this.allItems
        // Remove any items that are listed to be excluded
        .filter((item) => !this.exclude.includes(item[this.valueKey]))
        // Format for the dropdown component
        .map((item) => ({
          label: item[this.resolvedLabelKey],
          value: item[this.valueKey],
        }));

      // Add on missing options that are already selected, if they are missing
      const optionValues = options.map(({ value }) => value);
      const selectedButMissingOptions = this.selectedItems
        .filter((candidate) => !optionValues.includes(candidate.value));

      // If we're searching for items, then put the selected options that aren't part of the search
      // below the matched search results
      if (this.searchTerm.length === 0 || !this.selectedItems.length) {
        return selectedButMissingOptions.concat(options);
      }

      // Otherwise we'll put the missing selected options at the top.
      return options.concat(selectedButMissingOptions);
    },
    /**
     * Computes an object that can be applied to ChecDropdown with v-bind
     *
     * @returns {Object}
     */
    dropdownProps() {
      return Object.keys(dropdownProps)
        // Exclude variant which controls disabled state
        .filter((key) => key !== 'variant')
        .reduce((props, key) => ({
          ...props,
          [key]: this[key],
        }), {});
    },
    /**
     * Calculates the variant for the dropdown, using the given prop as a fallback
     *
     * @return {String}
     */
    passthroughVariant() {
      if (!this.initialised) {
        return 'disabled';
      }

      return this.variant;
    },
    passthroughValue() {
      if (!this.initialised) {
        return this.multiselect ? [] : '';
      }

      return this.value;
    },
    /**
     * "Label key" refers to the key (or property) on the resource that should be used as the label
     * for options in the dropdown. This component will assume a property on the resource that
     * should be used as a label, if one is not given.
     */
    resolvedLabelKey() {
      if (this.labelKey) {
        return this.labelKey;
      }

      if (!Array.isArray(this.allItems) || this.allItems.length === 0) {
        return null;
      }

      return ['name', 'label', 'title'].find(
        (candidate) => Object.hasOwnProperty.call(this.allItems[0], candidate),
      );
    },
    /**
     * Computes whether the search bar should show in the dropdown
     *
     * @returns {Boolean}
     */
    showSearch() {
      return this.searchTerm.length > 0 || this.totalCountWithNoSearch > this.minSearchAmount;
    },
    /**
     * Whether to show the loading indicator at the bottom of the dropdown options, implying that
     * more records are still being fetched
     */
    showLoadingInDropdown() {
      if (this.searchTerm.length > 0) {
        return this.isSearching;
      }

      // Show loading when we're not showing as many items as there should be
      return this.allItems.length !== this.totalCount;
    },
  },
  watch: {
    /**
     * Watches the VueX bound page, and instead concatenates unseen entries to existing items
     *
     * @param {Array} newValue
     */
    items(newValue) {
      this.addItems(newValue);
    },
  },
  mounted() {
    // Clear any search
    this.setSearch({ term: '' });
    // Reset the list of items to the first page
    this.setPage({ page: 1, context: this.context, isLoading: true })
      // Handle unloaded items that are selected
      .then(() => {
        // Store the total count as it is initially, when it's not updated for any search term
        this.totalCountWithNoSearch = this.totalCount;

        // The value is either a string or an array here. Check if nothing is selected
        if (!this.value || this.value.length <= 0) {
          this.initialised = true;
          return;
        }

        // Resolve the current value of the dropdown to an array
        const currentValue = typeof this.value === 'string' ? [this.value] : this.value;
        // Filter the given values to find any that are not a dropdown option and need to be loaded
        const unloadedIds = currentValue.filter((candidateValue) => (
          this.dropdownOptions.findIndex((predicate) => (
            predicate.value === candidateValue
          )) < 0
        ));

        if (unloadedIds.length === 0) {
          this.initialised = true;
          this.refreshSelectedItems();
          return;
        }

        // Load these additional options
        this.loadItem({ ids: unloadedIds }).then(() => {
          this.addItems(this.additionalItems);
          this.initialised = true;
          this.refreshSelectedItems();
        });
      });
  },
  unmounted() {
    // Clear the search when removing the component
    this.search({ term: '' });
  },
  methods: {
    /**
     * Add an array of items to the full list of options
     *
     * @param {Array} items
     */
    addItems(items) {
      const itemsAsValues = this.allItems.map((existingItem) => existingItem[this.valueKey]);

      this.allItems = this.allItems.concat(
        items.filter((item) => !itemsAsValues.includes(item[this.valueKey])),
      );
    },
    /**
     * Handle selections in the dropdown. Proxies though the original event dispatched by the
     * dropdown
     */
    emitInput(...args) {
      this.$emit('input', ...args);
      this.$nextTick(() => this.refreshSelectedItems());
    },
    /**
     * Perform a search by resetting state and dispatching the search action.
     */
    doSearch() {
      this.allItems = [];
      this.page = 1;
      this.search({ term: this.searchTerm }).catch((error) => {
        if (error instanceof Cancel) {
          return true;
        }
        throw error;
      });
    },
    /**
     * A 500ms debounced version of doSearch as the event is triggered by key-presses, and it should
     * wait for the user to stop typing.
     */
    debounceSearch: debounce(function debounceSearch() {
      this.doSearch();
    }, 500),
    /**
     * Handle the "search" event emitted by the dropdown
     *
     * @param {String} search
     */
    handleSearch(search) {
      this.searchTerm = search;

      // If VueX already knows about everything then the search should be immediate, so we don't
      // need to debounce it
      if (this.isFullyLoaded(this.context)) {
        this.doSearch();
        return;
      }

      this.debounceSearch();
    },
    /**
     * Handle the "scroll to bottom" event emitted by the dropdown
     */
    handleLoadMore() {
      // Don't do anything if we're already loading something or we've been here before and know
      // that we're fully loaded
      if (this.loading) {
        return;
      }

      // Check if all the items are showing already
      if (this.allItems.length === this.totalCount) {
        return;
      }

      this.loading = true;
      this.page += 1;

      // If the page number is higher than makes sense, return back to 1 in case there's been new
      // records appended during this time.
      if ((this.page - 1) * this.pageSize > this.totalCount) {
        this.page = 1;
      }

      // Dispatch the action, and handle loading state locally
      this.setPage({ page: this.page, context: this.context, isLoading: true }).then(() => {
        this.loading = false;
      });
    },
    /**
     * We track label and value of the selected items (rather than just the value) as we will be
     * dynamically changing the options in the dropdown based on the search. Existing selected items
     * will remain as options.
     */
    refreshSelectedItems() {
      // Loop through the value and find options to match
      this.selectedItems = (this.multiselect ? this.passthroughValue : [this.passthroughValue])
        .map((id) => {
          // Take all items (which might be filtered by a search) and then concat the existing items
          // that were selected before this refresh and pull out a result that matches the value
          // we're mapping over.
          const item = this.allItems
            .concat(this.selectedItems.map(({ value, label }) => ({
              [this.resolvedLabelKey]: label,
              [this.valueKey]: value,
            })))
            .find((candidate) => candidate[this.valueKey] === id);

          // There _should_ be an item, but we won't die if there isn't one.
          if (!item) {
            return null;
          }

          return {
            label: item[this.resolvedLabelKey],
            value: item[this.valueKey],
          };
        })
        .filter((candidate) => candidate !== null);
    },
  },
};
</script>
