<template>
  <ChecSlideoutPanel
    :title="panelTitle"
    close-on-overlay-click
    class="shipping-zone-add-edit"
    @close="handleClose"
  >
    <ChecLoading v-if="isLoading || saving" />
    <div v-else class="shipping-zone-add-edit__content space-y-8">
      <div class="shipping-zone-add-edit__name">
        <DashboardHeader
          header-tag="h3"
          :title="$t('shipping.zoneName')"
          margin="small"
          class="shipping-zone-add-edit__name-header"
        />
        <div class="input-wrapper">
          <ChecFormField :tooltip="$t('shipping.zoneNameDescription')">
            <TextField
              v-model="zone.name"
              name="shipping-name"
              required
              :label="$t('general.name')"
            />
          </ChecFormField>
          <span v-if="validationErrors.name" class="input-wrapper__error">
            {{ validationErrors.name }}
          </span>
        </div>
      </div>

      <RatesSection
        v-if="isAdding || zoneId"
        :deleted-rate-ids="deletedRateIds"
        :edited-rates="updatedRates"
        :new-rates="newRates"
        :rates="rates"
        :loading="isLoadingRates"
        :show-validation="validated"
        @add-rate="newRates = [...newRates, createBlankRate()]"
        @delete-rate="handleDeleteRate"
        @remove-new-rate="handleRemoveNewRate"
        @set-edited-rates="(changedRates) => updatedRates = changedRates"
        @set-new-rates="(changedRates) => newRates = changedRates"
        @undo-delete-rate="handleUndoDeleteRate"
      />

      <CountriesRegionsTable
        :zone="zone"
        :new-countries="newCountries"
        :deleted-countries="deletedCountries"
        @add-countries="handleAddCountries"
        @edit-regions="handleEditRegions"
        @delete-country="handleDeleteCountry"
        @undo-delete-country="handleUndoDeleteCountry"
      />

      <EnableOnProducts
        v-if="isAdding"
        v-model="enabledProducts"
        @toggle-all-products="(enableProducts) => enableAllProducts = enableProducts"
      />
    </div>
    <template #toolbar>
      <div class="shipping-zone-add-edit__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="handleClose">
          {{ $t('general.cancel') }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="isLoading || saving || !canSave"
          @click="save"
        >
          {{ saveButtonLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecFormField,
  ChecLoading,
  ChecSlideoutPanel,
  TextField,
} from '@chec/ui-library';
import { mapActions } from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import addNotification from '@/mixins/addNotification';
import crud from '@/mixins/crud';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import { actions } from '@/modules/fulfillment/store';
import shippingZoneSchema from '../../../fulfillment/schemas/zone';
import shippingRatesSchema from '../../../fulfillment/schemas/rates';
import CountriesRegionsTable from '../../components/shipping/CountriesRegionsTable.vue';
import RatesSection from '../../components/shipping/RatesSection.vue';
import EnableOnProducts from '../../components/shipping/EnableOnProducts.vue';

export default {
  name: 'AddEditShippingZone',
  components: {
    ChecButton,
    ChecFormField,
    ChecLoading,
    ChecSlideoutPanel,
    DashboardHeader,
    RatesSection,
    TextField,
    CountriesRegionsTable,
    EnableOnProducts,
  },
  mixins: [
    addNotification,
    crud('fulfillment/zones'),
    crud('fulfillment/rates', true, function getContext() {
      if (this.isAdding) {
        return { zone: null };
      }

      return { zone: this.zoneId };
    }, true),
  ],
  data() {
    return {
      saving: false,
      zone: {
        name: '',
        countries: [],
        subdivisions: {},
      },
      deletedCountries: [], // Track array of deleted countries
      newCountries: [], // Track countries that have been added but not saved yet
      validationErrors: {},
      validated: false, // Whether the user pressed the save button and validation has occured
      deletedRateIds: [],
      newRates: [
        // Have an empty new rate in the table by default
        // this.createBlankRate(),
      ],
      updatedRates: {},
      enabledProducts: [],
      enableAllProducts: true,
    };
  },
  computed: {
    /**
     * Determine whether panel is in 'adding' mode by checking the route
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'settings.shipping.add';
    },
    /**
     * Create the shipping zone panel title
     *
     * @returns {string}
     */
    panelTitle() {
      if (this.isAdding) {
        return this.$t('shipping.addZone');
      }
      return this.$t('shipping.editZone', { name: this.isLoading ? '' : this.zone.name });
    },
    /**
     * @returns {string}
     */
    saveButtonLabel() {
      if (this.saving) {
        return this.$t('general.saving');
      }
      return this.$t('general.saveChanges');
    },
    /**
     * Verify that the zone name has been filled,
     * That a country is selected,
     * that new rates have a name and an amount set,
     * and finally that any updated rates are filled.
     *
     * @returns {boolean}
     */
    canSave() {
      return this.zone.name !== ''
        && this.zone.countries.length > 0
        && this.hasNoEmptyNewRates
        && this.hasNoEmptyUpdatedRates;
    },
    /**
     * Verify that there are no empty new rates
     *
     * @returns {boolean}
     */
    hasNoEmptyNewRates() {
      return this.newRates.findIndex(
        (rate) => rate?.name?.trim() === ''
        || (typeof rate?.rate === 'number' || rate?.rate?.trim() === ''),
      ) === -1;
    },
    /**
     * Verify that there are no empty updated rates
     *
     * @returns {boolean}
     */
    hasNoEmptyUpdatedRates() {
      return Object.values(this.updatedRates).findIndex(
        (rate) => rate?.name?.trim() === ''
        || (typeof rate?.rate === 'number' || rate?.rate?.trim() === ''),
      ) === -1;
    },
    /**
     * @returns {string|null}
     */
    zoneId() {
      if (this.isAdding) {
        return null;
      }

      return this.$route.params.id;
    },
  },
  /**
   * Fetch shipping zone, if editing
   */
  async mounted() {
    this.updatedRates = {};

    if (this.isAdding) {
      this.newRates = [
        this.createBlankRate(),
      ];
      await this.switchContextRates({ zone: null });
      return;
    }

    this.load(this.zoneId).then(() => {
      const { name, countries, subdivisions } = this.get(this.zoneId);

      this.zone = {
        name,
        countries,
        subdivisions,
      };
    }).catch(() => {
      this.$router.push({ name: 'notFound' });
    });

    this.loadPageRates(1);
  },
  methods: {
    ...mapActions('fulfillment', {
      assignProducts: actions.BULK_ASSIGN_SHIPPING,
    }),
    /**
     * Push country to new countries list, update zone with country
     */
    handleAddCountries(countries) {
      this.newCountries = [
        ...this.newCountries,
        ...countries,
      ];

      this.zone.countries = [
        ...this.zone.countries,
        ...countries,
      ];

      if (countries.length === 1) {
        this.setRegionsForCountry(countries, ['*']);
        return;
      }
      this.setRegionsForCountries(countries, ['*']);
    },
    /**
     * Set regions to reflect in countries regions table
     */
    handleEditRegions({ country, regions }) {
      this.setRegionsForCountry(country, regions);
    },
    /**
     * Set and updates regions in zone
     */
    setRegionsForCountry(country, regions) {
      this.zone.subdivisions[country] = regions;
    },
    /**
     * Set and updates regions in multiple zones
     */
    setRegionsForCountries(countries, regions) {
      const subdivisions = {
        ...this.zone.subdivisions,
      };

      countries.forEach((country) => {
        subdivisions[country] = regions;
      });

      this.zone.subdivisions = subdivisions;
    },
    /**
     * Create a rate row by default with initial state
     */
    createBlankRate() {
      return {
        name: '',
        rate: '',
      };
    },
    handleClose() {
      this.$router.push({ name: 'settings.shipping' });
    },
    /**
     * Add country to delete to list of deleted countries
     */
    handleDeleteCountry(country) {
      if (this.newCountries.includes(country)) {
        const index = this.zone.countries.findIndex((candidate) => candidate === country);
        this.zone.countries.splice(index, 1);

        const { [country]: _, ...subdivisions } = this.zone.subdivisions;
        this.zone.subdivisions = subdivisions;

        this.newCountries.splice(
          this.newCountries.findIndex((candidate) => candidate === country),
          1,
        );

        return;
      }

      // If country to delete is already included in the list of deletedCountries,
      // don't execute function
      if (this.deletedCountries.includes(country)) {
        return;
      }
      // Add country to deletedCountries array
      this.deletedCountries.push(country);
    },
    /**
     * Remove country from list of countries to delete
     */
    handleUndoDeleteCountry(country) {
      // Find the index of the country to exclude from the deleted countries list
      const countryIndex = this.deletedCountries.findIndex((candidate) => candidate === country);

      // Don't execute function if index is -1 - does not exist
      if (countryIndex < 0) {
        return;
      }

      this.deletedCountries.splice(countryIndex, 1);
    },
    handleDeleteRate(rateId) {
      if (this.deletedRateIds.includes(rateId)) {
        return;
      }

      this.deletedRateIds.push(rateId);
    },
    handleRemoveNewRate(index) {
      // Splice out the indicated rate
      this.newRates.splice(index, 1);
    },
    handleUndoDeleteRate(rateId) {
      const index = this.deletedRateIds.findIndex((candidate) => candidate === rateId);

      if (index < 0) {
        return;
      }

      this.deletedRateIds.splice(index, 1);
    },
    async save() {
      this.saving = true;
      this.validationErrors = {};

      // Validate the zone and the rates together
      // Parse all updated rates to exclude some of them
      const updatedRates = Object.values(this.updatedRates)
        .filter((candidate) => (
          // Exclude deleted rates from those that should be updated
          !this.deletedRateIds.includes(candidate.id)
          // Exclude blank rate rows
          || (candidate.name.length === 0 && candidate.rate.length === 0)
        ));
      const newRates = this.newRates.filter(({ rate, name }) => rate !== '' || name !== '');

      this.validated = true;
      let validatedZone;
      try {
        // We only want the validation result for the zone, but don't care about the rates
        ([validatedZone] = await Promise.all([
          validateSchemaRequest(
            shippingZoneSchema,
            this.zone,
            { abortEarly: false },
          ).catch((error) => {
            this.validationErrors = error.errors;
            throw error;
          }),
          validateSchemaRequest(
            shippingRatesSchema,
            [...updatedRates, ...newRates],
            { abortEarly: false },
          ),
        ]));
      } catch (error) {
        this.saving = false;
        return;
      }

      const countriesToSave = this.zone.countries.filter(
        // Exclude countries from data that are in the deletedCountries list
        (candidate) => !this.deletedCountries.includes(candidate),
      );
      const zonePayload = {
        ...validatedZone,
        countries: countriesToSave,
      };

      const { isAdding } = this;

      // Save the zone
      const promise = this.isAdding
        ? this.create(zonePayload, true, false)
        : this.update(this.$route.params.id, zonePayload, true, false);
      let zone;

      try {
        zone = await promise;
      } catch (error) {
        this.addNotification(this.$t('shipping.saveFailure'), 'error');
        return;
      }

      // Ensure context is set for the new zone ID
      await this.switchContextRates({ zone: zone.id });

      // Rates
      const promises = [];
      updatedRates.forEach((rate) => {
        promises.push(this.updateRates(rate.id, rate, false));
      });
      newRates.forEach((rate) => {
        promises.push(this.createRates(rate, false));
      });
      this.deletedRateIds.forEach((id) => {
        promises.push(this.deleteRates(id));
      });

      try {
        await Promise.all(promises);
      } catch (error) {
        this.saving = false;
        this.addNotification(this.$t('shipping.saveFailure'), 'error');
        return;
      }
      // Assign the shipping rates to the products
      this.assignShippingToProducts(zone);

      // Reset state on changed rates.
      this.updatedRates = {};
      this.newRates = [this.createBlankRate()];
      this.deletedRateIds = [];

      // Reset state on new countries.
      this.newCountries = [];

      // Show a notification
      this.addNotification(this.$t('shipping.saveSuccess'));

      // Close panel after saving if there are deleted countries
      if (this.deletedCountries.length > 0) {
        this.handleClose();
      } else if (isAdding) {
        // Redirect to the edit route if we've just added the zone.
        this.$router.push({ name: 'settings.shipping.edit', params: { id: zone.id } });
      }

      // Update the subdivisions to show count.
      this.zone.subdivisions = zone.subdivisions;

      this.validated = false;
      this.saving = false;
    },
    assignShippingToProducts(zone) {
      // Assign no products
      if (!this.enableAllProducts && !this.enabledProducts.length) {
        return;
      }
      // Assign either all or a specific set of products
      this.assignProducts({
        zoneId: zone.id,
        products: this.enabledProducts,
      });
    },
  },
};
</script>

<style lang="scss">
.shipping-zone-add-edit {
  &__toolbar {
    @apply flex justify-end w-full;
  }
}
</style>
