<template>
  <tr class="rate-row">
    <td>
      <div class="input-wrapper space-x-2">
        <TextField
          v-model="name"
          name="rate-name"
          :label="$t('general.name')"
          :variant="nameFieldVariant"
          :required="Boolean(rate.id || amount.length > 0)"
        />
        <span v-if="showValidation && name === ''" class="input-wrapper__error">
          {{ $t('shipping.rateNameRequired') }}
        </span>
      </div>
    </td>
    <td>
      <div class="input-wrapper space-x-2">
        <TextField
          v-model="amount"
          name="rate-amount"
          currency
          type="number"
          step=".01"
          :currency-symbol="merchant.currency.symbol"
          :label="$t('general.amount')"
          :variant="amountFieldVariant"
          :required="Boolean(rate.id || name.length > 0)"
        />
        <span v-if="showValidation && amount === ''" class="input-wrapper__error">
          {{ $t('shipping.ratePriceRequired') }}
        </span>
      </div>
    </td>
    <td>
      <ChecButton
        v-if="deleted"
        class="rate-row__actions"
        color="primary"
        outline
        variant="round"
        @click="$emit('undo-delete')"
      >
        {{ $t('general.cancel') }}
      </ChecButton>
      <ChecOptionsMenu v-else menu-placement="bottom-end" class="rate-row__actions">
        <ChecOption
          destructive
          @option-selected="handleDelete"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </tr>
</template>

<script>
import {
  ChecButton,
  ChecOption,
  ChecOptionsMenu,
  TextField,
} from '@chec/ui-library';
import { mapState } from 'vuex';

export default {
  name: 'RateRow',
  components: {
    ChecButton,
    ChecOption,
    ChecOptionsMenu,
    TextField,
  },
  props: {
    deleted: Boolean,
    rate: Object,
    /**
     * Whether validation error should appear on this row if it is missing detail
     */
    showValidation: Boolean,
  },
  computed: {
    ...mapState('merchant', ['merchant']),
    name: {
      get() {
        return this.rate.name;
      },
      set(name) {
        const { rate } = this.rate;
        this.$emit('change', {
          ...this.rate,
          name,
          rate: typeof rate === 'object'
            ? rate.raw
            : rate,
        });
      },
    },
    amount: {
      get() {
        const { rate } = this.rate;
        return typeof rate === 'object' ? rate.raw : rate;
      },
      set(rate) {
        this.$emit('change', {
          ...this.rate,
          rate,
        });
      },
    },
    nameFieldVariant() {
      if (this.deleted) {
        return 'disabled';
      }

      if (this.showValidation && this.name === '') {
        return 'error';
      }

      return '';
    },
    amountFieldVariant() {
      if (this.deleted) {
        return 'disabled';
      }

      if (this.showValidation && this.amount === '') {
        return 'error';
      }

      return '';
    },
  },
  methods: {
    handleDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss">
.rate-row {
  &__actions {
    @apply inline-flex;
  }

  .input-wrapper {
    @apply mb-0;
  }
}
</style>
