<template>
  <div class="shipping-zones">
    <DashboardTable
      class="shipping-zones__table"
      :align-right-after="1"
      :columns="[
        { title: $t('shipping.zone') },
        { title: $t('shipping.numCountries') },
        { title: $t('shipping.numRegions') },
        { title: $t('general.actions'), class: 'shipping-zones__options-title' },
      ]"
      show-search
      state-key="fulfillment/zones"
    >
      <ZoneRow
        v-for="zone in zones"
        :key="zone.id"
        :zone="zone"
        @handle-delete="handleDelete(zone.id)"
        @handle-edit="handleEdit(zone.id)"
      />
    </DashboardTable>
  </div>
</template>

<script>
import DashboardTable from '@/components/DashboardTable.vue';
import crud from '@/mixins/crud';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import ZoneRow from './ZoneRow.vue';

export default {
  name: 'ZoneTable',
  components: {
    DashboardTable,
    ZoneRow,
  },
  mixins: [
    crud('fulfillment/zones'),
    addNotification,
    confirm,
  ],
  props: {
    zones: Array,
  },
  methods: {
    async handleDelete(zoneId) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('shipping.confirmDeleteZone'),
      )) {
        return;
      }

      this.delete(zoneId)
        .then(() => {
          this.addNotification(this.$t('shipping.zoneDeleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('shipping.zoneDeleteFailed'), 'error');
          throw error;
        });
    },
    handleEdit(zoneId) {
      this.$router.push({ name: 'settings.shipping.edit', params: { id: zoneId } });
    },
  },
};
</script>

<style lang="scss">
.shipping-zones {
  &__options-title {
    @screen md {
      @apply w-1/11;
    }
  }
}
</style>
