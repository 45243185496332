<template>
  <div>
    <DashboardHeader margin="small" :title="$t('shipping.shippingZones')">
      <ChecButton
        variant="round"
        color="primary"
        icon="plus"
        tag-type="route"
        :to="{ name: 'settings.shipping.add' }"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </DashboardHeader>

    <ZoneTable :zones="zones" />

    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('shipping.introSplash.title')"
      button
      @click="() => $router.push({ name: 'settings.shipping.add' })"
    >
      <i18n path="shipping.introSplash.description" tag="span">
        <template #productPage>
          <router-link :to="{ name: 'products.home' }">
            {{ $t('shipping.productPage') }}
          </router-link>
        </template>
      </i18n>
    </IntroSplash>
    <Pagination
      keep-page-on-mount
      state-key="fulfillment/zones"
    />
    <router-view />
  </div>
</template>

<script>
import { ChecButton } from '@chec/ui-library';
import Pagination from '@/components/Pagination.vue';
import { mapPagination } from '@/lib/pagination';
import DashboardHeader from '@/components/DashboardHeader.vue';
import IntroSplash from '@/components/IntroSplash.vue';
import ZoneTable from '../components/shipping/ZoneTable.vue';

export default {
  name: 'Shipping',
  components: {
    ChecButton,
    DashboardHeader,
    IntroSplash,
    ZoneTable,
    Pagination,
  },
  computed: {
    ...mapPagination('fulfillment/zones'),
    showIntroSplash() {
      return (this.zones.length === 0 && !this.isLoading)
        || (this.zones.length === 1
          && this.zones[0].updated === this.zones[0].created
          && this.zones[0].name === 'Domestic (United States)');
    },
  },
};
</script>

<style lang="scss">

</style>
