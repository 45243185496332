<template>
  <div class="enable-on-product">
    <DashboardHeader
      header-tag="h3"
      :title="$t('shipping.enableOnProductsTitle')"
      margin="small"
    >
      <ChecSwitch
        v-model="allProducts"
        name="allProducts"
        @change="handleShowProductSelector"
      >
        {{ switchLabel }}
      </ChecSwitch>
    </DashboardHeader>
    <ChecAlert
      class="enable-on-product__alert"
      variant="info"
      inline
      disable-close
      show-icon
    >
      {{ $t('shipping.enableOnProductsInfo') }}
    </ChecAlert>
    <div
      v-if="!allProducts"
      class="input-wrapper"
    >
      <ResourceDropdown
        v-if="!isLoading"
        v-model="productsData"
        name="productsSelected"
        state-key="products"
        :label="$t('product.product')"
        multiselect
      />
      <template v-else>
        <ChecLoading class="enable-on-product__loading" />
        {{ messageCopy }}
      </template>
    </div>
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecLoading,
  ChecSwitch,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import DashboardHeader from '@/components/DashboardHeader.vue';
import ResourceDropdown from '@/components/ResourceDropdown.vue';
import actions from '@/lib/pagination/actions';

export default {
  name: 'EnableOnProducts',
  components: {
    ChecAlert,
    ChecLoading,
    ChecSwitch,
    DashboardHeader,
    ResourceDropdown,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      allProducts: true,
    };
  },
  computed: {
    ...mapState('products', ['totalCount']),
    productsData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    messageCopy() {
      return this.isLoading
        ? this.$t('general.loading')
        : this.$t('product.noRelatedAvailable');
    },
    switchLabel() {
      return this.allProducts
        ? this.$t('general.enabled')
        : this.$t('general.disabled');
    },
  },
  watch: {
    allProducts() {
      this.handleShowProductSelector();
    },
  },
  methods: {
    ...mapActions('products', { loadProducts: actions.LOAD_REMAINING_ITEMS }),
    handleShowProductSelector() {
      this.$emit('toggle-all-products', this.allProducts);
      if (this.allProducts) {
        this.$emit('change', []);
        return;
      }
      if (this.products === undefined) {
        this.loadProducts().then(() => {
          this.isLoading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.enable-on-product {
  @apply relative;

  &__alert {
    @apply mb-4;
  }

  .loading--with-background {
    // Override to deal with panel BG
    background: rgb(248, 250, 253, 0.9);
  }
}
</style>
