<template>
  <ChecSlideoutPanel
    :title="titleLabel"
    close-on-overlay-click
    class="shipping-regions"
    @close="handleClose"
  >
    <ChecAlert
      show-icon
      inline
      disable-close
      variant="info"
      class="shipping-regions__help-notice"
    >
      {{ $t('shipping.countriesRegionsHelp') }}
    </ChecAlert>
    <div
      v-if="regionOptions.length === 0 && loadingSubdivisions"
      class="shipping-regions__loading-input"
    >
      <ChecLoading without-background />
    </div>
    <ChecDropdown
      v-else
      v-model="regions"
      :label="$t('general.regions')"
      multiselect
      name="regions"
      :options="regionOptions"
      required
      :search-value="searchRegion"
      show-search
      :variant="country === '' ? 'disabled' : ''"
      @search="(value) => searchRegion = value"
    />
    <template #toolbar>
      <div class="shipping-regions__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="handleClose">
          {{ $t('general.cancel') }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="regionOptions.length === 0 && loadingSubdivisions"
          @click="emitRegions"
        >
          {{ $t('general.saveAndClose') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecDropdown,
  ChecLoading,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import actions from '@/store/actions';

export default {
  name: 'RegionsPanel',
  components: {
    ChecAlert,
    ChecButton,
    ChecDropdown,
    ChecLoading,
    ChecSlideoutPanel,
  },
  props: {
    /**
     * Current country being edited
     */
    editedCountry: String,
    /**
     * Current country's regions being edited
     */
    editedRegions: Array,
  },
  data() {
    return {
      country: '',
      loadingSubdivisions: false,
      searchRegion: '',
      regions: [],
    };
  },
  computed: {
    ...mapState({
      fullCountryNames: 'countries',
      subdivisions: 'subdivisions',
    }), // Change countries to obj with named keys
    ...mapState('fulfillment/zones', { zones: 'items' }),
    /**
     * Returns a list of subdivisions/regions options
     *
     * @returns {Array}
     */
    regionOptions() {
      // Return empty array if there is no selected country
      if (!Object.hasOwnProperty.call(this.subdivisions, this.country)) {
        return [];
      }

      // Filter countries based on a search (if there is one)
      const regions = this.searchRegion.length > 0
        ? Object.entries(this.subdivisions[this.country])
          .filter((region) => region[1].toLowerCase().includes(this.searchRegion.toLowerCase()))
        : Object.entries(this.subdivisions[this.country]);

      return regions.map(([value, label]) => ({ value, label }));
    },
    /**
     * Determines the panel title based on the country selected
     *
     * @returns {string}
     */
    titleLabel() {
      return this.$t('shipping.editRegions', { country: this.fullCountryNames[this.country] });
    },
  },
  mounted() {
    this.setCountry(this.editedCountry);
  },
  methods: {
    ...mapActions({ fetchSubdivisions: actions.FETCH_SUBDIVISIONS }),
    handleClose() {
      this.$emit('close');
    },
    /**
     * Emits the regions data then close the panel
     */
    emitRegions() {
      const { country, regions } = this;
      this.$emit('change', { country, regions });
      this.handleClose();
    },
    /**
     * Sets the country and make an API request to fetch subdivisions
     */
    async setCountry(country) {
      this.country = country;

      const hasSubdivisions = Object.hasOwnProperty.call(this.subdivisions, country);
      // Fetch subdivisions for the selected country
      if (hasSubdivisions) {
        if (this.editedRegions[0] === '*') {
          this.regions = Object.keys(this.subdivisions[this.country]);
        } else {
          this.regions = this.editedRegions;
        }
        return;
      }

      this.regions = [];

      this.loadingSubdivisions = true;
      await this.fetchSubdivisions(country);

      if (this.editedRegions[0] === '*') {
        this.regions = Object.keys(this.subdivisions[this.country]);
      } else {
        this.regions = this.editedRegions;
      }
      this.loadingSubdivisions = false;
    },
  },
};
</script>

<style lang="scss">
.shipping-regions {
  &__toolbar {
    @apply flex justify-end w-full;
  }

  &__help-notice {
    @apply mb-2;
  }

  &__loading-input {
    @apply relative bg-white rounded border border-gray-200 duration-150 outline-none transition
      shadow-sm py-4;

    // 1rem for padding (x2 for top and bottom) + 1.25rem for line height
    height: 3.25rem;

    &:focus,
    &:active {
      @apply transition duration-150 border-gray-500 shadow-light-focus;
    }
  }
}
</style>
