<template>
  <ChecSlideoutPanel
    :title="$t('shipping.addCountries')"
    close-on-overlay-click
    class="shipping-countries"
    @close="handleClose"
  >
    <ChecAlert
      show-icon
      inline
      disable-close
      variant="info"
      class="shipping-countries__help-notice"
    >
      {{ $t('shipping.countriesRegionsHelp') }}
    </ChecAlert>
    <div class="input-wrapper">
      <ChecDropdown
        v-model="countries"
        :label="$t('address.country')"
        name="country"
        :options="countryOptions"
        required
        multiselect
        :search-value="searchCountry"
        show-search
        :value="countries"
        @input="checkAllSelected"
        @search="(value) => searchCountry = value"
      />
    </div>
    <template #toolbar>
      <div class="shipping-countries__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="handleClose">
          {{ $t('general.cancel') }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="countries.length === 0"
          @click="handleSelectCountries"
        >
          {{ $t('general.saveAndClose') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecDropdown,
  ChecSlideoutPanel,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import actions from '@/store/actions';

export default {
  name: 'CountriesPanel',
  components: {
    ChecAlert,
    ChecButton,
    ChecDropdown,
    ChecSlideoutPanel,
  },
  props: {
    /**
     * Current country's added to this record
     */
    currentCountries: Array,
  },
  data() {
    return {
      countries: [],
      searchCountry: '',
      allCountriesSelected: false,
    };
  },
  computed: {
    ...mapState({
      fullCountryNames: 'countries',
      subdivisions: 'subdivisions',
    }), // Change countries to obj with named keys
    ...mapState('fulfillment/zones', { zones: 'items' }),
    /**
     * Exclude countries from the countryOptions list if country is already included
     * in an existing zone
     *
     * @returns {Array}
     */
    existingCountries() {
      return this.zones.reduce((zone, obj) => zone.concat(obj.countries), []);
    },
    /**
     * Return a list of filtered country options if there is a search input
     * or return a full list of countries
     *
     * @returns {Array}
     */
    countryOptions() {
      // Filter countries based on a search (if there is one)
      const countries = this.searchCountry.length > 0
        ? Object.entries(this.fullCountryNames)
          // Make country label lower case to prevent case sensitivity in search input
          .filter((country) => country[1].toLowerCase().includes(this.searchCountry.toLowerCase()))
        : Object.entries(this.fullCountryNames);

      // Filter out existing countries from the options list by comparing country codes
      const filteredCountries = countries
        .filter((candidate) => !this.existingCountries.includes(candidate[0]))
        .filter((candidate) => !this.currentCountries.includes(candidate[0]));

      // If searching, return filtered countries
      if (this.searchCountry.length > 0) {
        return filteredCountries.map(([value, label]) => ({ value, label }));
      }
      // Otherwise return with 'all' option
      return [
        {
          label: this.$t('general.all'),
          value: 'all',
        },
        ...filteredCountries.map(([value, label]) => ({ value, label })),
      ];
    },
  },
  methods: {
    ...mapActions({ fetchSubdivisions: actions.FETCH_SUBDIVISIONS }),
    handleClose() {
      this.$emit('close');
    },
    handleSelectCountries() {
      if (this.countries[0] === 'all') {
        this.$emit('change', this.countries.slice(1));
        this.handleClose();
        return;
      }
      this.$emit('change', this.countries);
      this.handleClose();
    },
    /**
     * Check to see if the 'All' option is selected or not and update
     * the selected countries accordingly.
     */
    checkAllSelected(values) {
      if (!this.allCountriesSelected && values.includes('all')) {
        this.countries = [
          'all',
          ...Object.entries(this.fullCountryNames)
            .filter((candidate) => !this.existingCountries.includes(candidate[0]))
            .filter((candidate) => !this.currentCountries.includes(candidate[0]))
            .map(([value]) => value),
        ];
        this.allCountriesSelected = true;
      }
      if (this.allCountriesSelected && !values.includes('all')) {
        this.countries = [];
        this.allCountriesSelected = false;
      }
    },
  },
};
</script>

<style lang="scss">
.shipping-countries {
  &__toolbar {
    @apply flex justify-end w-full;
  }

  &__help-notice {
    @apply mb-2;
  }

  &__loading-input {
    @apply relative bg-white rounded border border-gray-200 duration-150 outline-none transition
      shadow-sm py-4;

    // 1rem for padding (x2 for top and bottom) + 1.25rem for line height
    height: 3.25rem;

    &:focus,
    &:active {
      @apply transition duration-150 border-gray-500 shadow-light-focus;
    }
  }
}
</style>
