<template>
  <TableRow
    :id="zone.id"
    route-name="settings.shipping.edit"
  >
    <td>
      <router-link :to="{ name: 'settings.shipping.edit', params: { id: zone.id } }">
        {{ zone.name || $t('general.notAvailable') }}
      </router-link>
    </td>
    <td>
      {{ numberOfCountries }}
    </td>
    <td>
      {{ Object.values(zone.subdivisions).flat().length }}
    </td>
    <td>
      <ChecOptionsMenu menu-placement="bottom-end" class="dashboard-table__actions-button">
        <ChecOption
          @option-selected="$emit('handle-edit')"
        >
          {{ $t('general.edit') }}
        </ChecOption>
        <ChecOption
          destructive
          @option-selected="$emit('handle-delete')"
        >
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';

export default {
  name: 'ZoneRow',
  components: {
    TableRow,
    ChecOption,
    ChecOptionsMenu,
  },
  props: {
    zone: Object,
  },
  computed: {
    /*
     * Return the number of countries, if none found show 0.
     */
    /**
     * @returns {number}
     */
    numberOfCountries() {
      return this.zone.countries?.length
      || 0;
    },
  },
};
</script>
