<template>
  <div
    class="variants-empty-message bg-confetti"
    :class="{['variants-empty-message--big']: big}"
  >
    <ChecCard compact class="variants-empty-message__card">
      <h3 class="variants-empty-message__header">
        {{ title }}
      </h3>
      <slot />
    </ChecCard>
  </div>
</template>

<script>
import { ChecCard } from '@chec/ui-library';

export default {
  name: 'EmptyMessage',
  components: {
    ChecCard,
  },
  props: {
    title: String,
    big: Boolean,
  },
};
</script>

<style lang="scss">
.variants-empty-message {
  @apply pt-24 pb-40 mt-4 whitespace-pre-line;

  &--big {
    @apply py-48;
  }

  &__card {
    @apply mx-auto max-w-xl;

    &.card--compact,
    &.card--compact .card__inner-wrapper {
      // Overriding default card styles for some reason (as per designs)
      @apply rounded-lg;
    }
  }

  &__header {
    @apply mb-4 font-bold;
  }
}
</style>
