import {
  array, object, string,
} from 'yup';

const priceReg = /^\d+(\.\d+)?$|^$/;

export default array().of(object().shape({
  name: string().required(),
  rate: string().required().matches(priceReg),
}));
